import React, { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { Fulfillment, InfoType } from 'types/store'

import useStyles from './style'

interface InfoProps {
  times: InfoType
  fulfillment: Fulfillment | null
}
const Information: FC<InfoProps> = ({ times, fulfillment }) => {
  const classes = useStyles()
  if (!fulfillment) {
    return null
  }

  const details = times[fulfillment]

  if (!details) {
    return null
  }

  return (
    <div className={classes.container}>
      {details &&
        details.length > 0 &&
        details.map((item, index) => {
          return (
            <div key={item.label} className={clsx(classes.info, index > 0 && classes.infoBorder)}>
              <Typography align="center" variant="h6">
                {item.label}
              </Typography>
              {item.info && (
                <Typography align="center" variant="body2">
                  {item.info}
                </Typography>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default Information
