import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  logo: {
    maxWidth: '50%',
    marginBottom: 20,
  },
  action: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    marginBottom: 10,
  },
}))

export default useStyles
