import React, { FC } from 'react'
import { Typography } from '@mui/material'

import useStyles from './style'

interface ResumenProps {
  name: string
  info: string[]
}

const Resumen: FC<ResumenProps> = ({ name, info }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="h5">{name}</Typography>
      {info &&
        info.length > 0 &&
        info.map((inf) => {
          return (
            <Typography key={inf} variant="body2">
              {inf}
            </Typography>
          )
        })}
    </div>
  )
}

export default Resumen
