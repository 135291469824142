import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    paddingRight: 35,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
    flexDirection: 'column',
  },
  taxes: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    paddingTop: 5,
    paddingBottom: 5,
  },
  price: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default useStyles
