import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 35,
    marginBottom: 20,
    fontSize: '10vw',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
}))

export default useStyles
