import React from 'react'
import { Fees } from 'components'
import moment from 'moment'

const Delivery = ({ deliveryDate }) => {
  if (!deliveryDate) {
    return null
  }
  const isToday = moment().isSame(deliveryDate, 'day')

  const getMessage = (frt: string) => String(moment(deliveryDate).format(frt))

  const message = isToday ? `Today - ${getMessage('hh:mmA')}` : getMessage('MMM DD - hh:mmA')

  return (
    <Fees
      countItems={1}
      taxesList={{
        taxes: [
          {
            isValid: true,
            formatted: 'Estimated delivery time',
            formattedAmount: message,
            info: '',
          },
        ],
      }}
    />
  )
}

export default Delivery
