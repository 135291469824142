import { useEffect, useState } from 'react'
import validate from 'utils/validations'
import deepSet from 'set-value'

export default function useSimpleFormData(initialValues, initialTouched, entity, schema) {
  const [formState, setFormState] = useState({
    values: { ...initialValues },
    isValid: false,
    touched: entity ? initialTouched : {},
    errors: {},
  })

  //  Update formState
  useEffect(() => {
    const errors = validate(formState.values, schema)
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleFieldChange = (event, field, value) => {
    if (event.persist) {
      event.persist()
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]:
          event && event.target && event.target.type === 'checkbox' ? event.target.checked : value,
      },
      touched: {
        ...prevFormState.touched,
        [field]: true,
      },
    }))
  }

  const handleObjectChange = (field: string, value: number | string) => {
    const currentValues = formState.values
    const currentTouched = formState.touched
    deepSet(currentValues, field, value)
    deepSet(currentTouched, field, true)

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: currentValues,
      touched: currentTouched,
    }))
  }

  const hasError = (field: string) => formState?.touched[field] && formState?.errors[field]

  const getHelper = (field: string) => {
    if (hasError(field)) {
      return formState?.errors?.[field][0]
    }
    return null
  }

  return {
    formState,
    setFormState,
    handleFieldChange,
    handleObjectChange,
    hasError,
    getHelper,
  }
}
