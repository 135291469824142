import React, { FC } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import useStyles from './style'

interface IModalMessage {
  open: boolean
  handleClose: (x: any, y?: any) => void
  logo?: string | null
  message?: string | null
  success: boolean
  errors?: string[] | null
}

const ModalMessage: FC<IModalMessage> = ({
  open,
  handleClose,
  logo = null,
  message = null,
  success,
  errors,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const classes = useStyles()
  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}>
      <DialogContent className={classes.center}>
        <img alt="Logo" className={classes.logo} src={logo || '/images/logo.png'} />
        <DialogContentText>
          {success ? (
            <>
              <Typography variant="h2">Thanks!</Typography>
              <Typography variant="h4">Your order has been placed.</Typography>
              <Typography variant="h4">You will get status updates by email and text.</Typography>
            </>
          ) : (
            <>
              <Alert variant="filled" color="error">
                {errors?.[0]}
              </Alert>
              <div className={classes.divider} />
              <Typography variant="h2">{errors?.[1]}</Typography>
            </>
          )}
          {message && <Typography variant="h5">{message}</Typography>}
        </DialogContentText>
        <div className={classes.action}>
          <Button variant="contained" onClick={handleClose}>
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalMessage
