import React, { Suspense, useState, useRef, useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { Container, LinearProgress, Link } from '@mui/material'
import RouteWithSubRoutes from 'utils/subRoutes'
import { Snackbar } from 'components'
import { useSnackbar } from 'hooks'
import { selectStore } from 'selectors/store'
import { useSelector } from 'react-redux'

import { TopBar, NavBar } from './components'
import useStyles from './style'

// RouteConfig

const Dashboard = (props) => {
  const classes = useStyles()
  const storeSelected = useSelector(selectStore)
  const inputEl = useRef<any>(null)
  const { routes } = props
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const [marginTop, setMarginTop] = useState<number>(0)
  const { snackbar } = useSnackbar()

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true)
  }

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false)
  }

  useEffect(() => {
    if (storeSelected) {
      setMarginTop(inputEl?.current?.clientHeight)
    }
  }, [storeSelected])

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        handleLogout={() => null}
        onOpenNavBarMobile={handleNavBarMobileOpen}
        refElem={inputEl}
      />
      <div
        className={`${classes.container} main-container`}
        id="main-container"
        style={{ marginTop }}>
        <main className={classes.content}>
          <Container>
            <Suspense fallback={<LinearProgress />}>
              <Switch>
                {routes.map((route) => (
                  <RouteWithSubRoutes key={route.path} {...route} />
                ))}
              </Switch>
            </Suspense>
          </Container>
          <Snackbar message={snackbar.message} open={snackbar.show} severity={snackbar.type} />
        </main>
        <NavBar
          className={classes.navBar}
          handleLogout={() => null}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
      </div>
      <div className={classes.powered}>
        Powered by{' '}
        <Link href="https://www.lovvett.com/download-app" target="_blank">
          Lovvett
        </Link>
      </div>
    </div>
  )
}

export default Dashboard
