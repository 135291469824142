import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from 'layouts/Auth'
import ErrorLayout from 'layouts/Error'
import DashboardLayout from 'layouts/Dashboard'
// import OverviewView from 'views/Overview'

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/" />,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Overview')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/congrats',
    component: AuthLayout,
    routes: [
      {
        path: '/purchase',
        exact: true,
        component: lazy(() => import('views/Thanks')),
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Errors/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Errors/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Errors/Error500')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/app/overview',
        exact: true,
        component: lazy(() => import('views/Overview')),
      },
      {
        path: '/thanks',
        exact: true,
        component: lazy(() => import('views/Thanks')),
      },
      {
        path: '/:id',
        exact: true,
        component: lazy(() => import('views/Store')),
      },
      {
        path: '/:id/checkout/:token',
        component: lazy(() => import('views/Payment')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
]

export default routes
