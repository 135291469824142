import React, { useEffect, useState, useRef, FC } from 'react'
import clsx from 'clsx'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import parseData from './helper'
import useStyles from './style'

interface IAutocomplete {
  country?: string
  region?: string
  disabled?: boolean
  placeholder: string
  focus?: boolean
  defaultValue: string
  className?: string
  onSelect: (v: any) => void
  onChange?: () => void
}
const AutocompletePlace: FC<IAutocomplete> = ({
  country,
  region,
  disabled = false,
  placeholder,
  focus = false,
  defaultValue,
  className = '',
  onSelect,
  onChange,
}) => {
  const eleAddress = useRef<any>(null)
  const classes = useStyles()
  const [address, setAddress] = useState(defaultValue)

  useEffect(() => {
    if (focus) {
      // eslint-disable-next- line
      eleAddress?.current?.focus()
    }
  })

  const handleSelect = (addressFounded: any) => {
    geocodeByAddress(addressFounded)
      .then((results) => {
        const addreessData = parseData(results[0])
        setAddress(results[0].formatted_address)
        onSelect?.(addreessData)
      })
      .catch((error) => console.error('Error:', error))
  }

  let searchOptions = {
    componentRestrictions: { country: country?.toLowerCase() },
  }
  if (region) {
    searchOptions = {
      ...searchOptions,
      // types: ['(regions)'],
    }
  }

  const handleChangeSelect = (naddress) => {
    if (naddress) {
      setAddress(naddress)
    } else {
      onSelect('')
    }
    onChange?.()
  }

  useEffect(() => {
    setAddress(defaultValue)
  }, [defaultValue])

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChangeSelect}
      onSelect={handleSelect}
      searchOptions={searchOptions}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={`content-autocomplete ${className}`}>
          <div className="">
            <input
              {...getInputProps({
                placeholder,
                // className: classes.input,
                className: 'content-autocomplete__input',
              })}
              disabled={disabled}
              ref={eleAddress}
            />
          </div>
          <div className={classes.ddcontainer}>
            {loading && <div className={classes.loading}>Loading...</div>}
            {suggestions.map((suggestion) => {
              const classNames = clsx(classes.item, suggestion.active && classes.active)
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: classNames,
                  })}>
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default AutocompletePlace
