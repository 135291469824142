import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    display: 'flex',
    marginBottom: 15,
    flexDirection: 'column',
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  normalTip: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    marginBottom: 10,
  },
  custom: {
    display: 'flex',
  },
}))

export default useStyles
