import React, { FC } from 'react'
import { Divider } from '@mui/material'
import { ItemCard } from 'components'
import { useSelector } from 'react-redux'
import { getItemMenu } from 'selectors/cart'
import { selectMenuCategories } from 'selectors/store'
import { IItems } from 'types/cart'
import { getInfoItem } from '../../helper'
import useStyles from './style'
import RenderHeader from '../RenderHeader'

interface IItemsCart {
  itemsOrdered: IItems[]
  onHandleQty: (q: number, qty: number, index: number) => void
  onHandleDeleteItem: (d: any) => void
}
const Items: FC<IItemsCart> = ({ itemsOrdered, onHandleQty, onHandleDeleteItem }) => {
  const classes = useStyles()
  const menus = useSelector(selectMenuCategories)

  if (!itemsOrdered || (itemsOrdered && itemsOrdered?.length === 0)) {
    return null
  }
  return (
    <div className={classes.items}>
      <RenderHeader />
      <Divider className={classes.divider} />
      {itemsOrdered.map((item: IItems, index) => {
        const menuItem = getItemMenu(menus, item.item_id)
        if (!menuItem) {
          return null
        }
        return (
          <ItemCard
            key={item.item_id}
            qty={item.quantity}
            menu={menuItem}
            price={item.price_before_taxes}
            info={getInfoItem(item)}
            onHandleQty={(q) => onHandleQty(q, item.quantity, index)}
            onHandleDelete={() => onHandleDeleteItem({ name: menuItem.name, index })}
          />
        )
      })}
    </div>
  )
}

export default Items
