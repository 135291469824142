import React, { FC, useEffect, useState } from 'react'
import { Typography, Card, CardMedia, Grid, Chip } from '@mui/material'
import clsx from 'clsx'
import { formatCurrency, formatPrice } from 'utils/helper'
import { WatchLater, WatchLaterOutlined } from '@mui/icons-material'

import useStyles from './style'

interface ItemProps {
  item?: any | null
  onHandle: (itemId: number) => void
}

const Item: FC<ItemProps> = ({ item, onHandle }) => {
  const classes = useStyles()
  const {
    name,
    description,
    show_price: price,
    lovvett_original_price: originalPrice,
    media,
    id,
    pickup_time_label,
  } = item

  const image = media?.length > 0 ? media[0]?.media?.url : null
  if (!item) {
    return null
  }

  const onHandleItem = (itemId) => {
    onHandle(itemId)
  }

  return (
    <Card className={classes.container} onClick={() => onHandleItem(id)}>
      <Grid xs={8} item>
        <div className={classes.information}>
          <div className={classes.topInfo}>
            <Typography variant="h5">{name}</Typography>
            {description !== '' && <Typography variant="body1">{description}</Typography>}
            <div className={classes.prices}>
              <div className={clsx([classes.price, originalPrice > 0 ? classes.newPrice : {}])}>
                {formatCurrency(formatPrice(price))}
              </div>
              {originalPrice > 0 && (
                <div className={classes.originalPrice}>
                  {formatCurrency(formatPrice(originalPrice))}
                </div>
              )}
            </div>
            {item.popular && <Chip label="Popular" size="small" color="primary" variant="filled" />}
          </div>
          {pickup_time_label !== '' && (
            <div className={classes.label}>
              <WatchLaterOutlined fontSize="small" />
              <Typography variant="body2">{pickup_time_label}</Typography>
            </div>
          )}
        </div>
      </Grid>
      <Grid xs={4} item className={classes.image}>
        {image && <CardMedia component="img" sx={{ height: 180 }} image={image} alt="Image food" />}
      </Grid>
    </Card>
  )

  Item.defaultProps = {
    item: null,
  }
}

export default Item
