import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    // backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  flexGrow: {
    flexGrow: 1,
  },
}))

export default useStyles
