import React, { FC } from 'react'
import { Avatar, Typography, Grid } from '@mui/material'

import useStyles from './style'

interface TitleProps {
  avatar: string
  title: string
  url?: string | null | undefined
}

const TitleTopCard: FC<TitleProps> = ({ title, avatar, url }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {url ? (
        <a href={url} rel="noreferrer">
          <Avatar alt={title} src={avatar} sx={{ width: 50, height: 50 }} />
        </a>
      ) : (
        <Avatar alt={title} src={avatar} sx={{ width: 50, height: 50 }} />
      )}
      <div className={classes.title}>{title}</div>
    </div>
  )
}
TitleTopCard.defaultProps = {
  url: null,
}

export default TitleTopCard
