import { STORE } from 'constants/ActionTypes'
import { IStoreReducer } from 'types/store'

const INITIAL_STATE: IStoreReducer = {
  store: null,
  error: null,
  menus: null,
  item: null,
  itemError: null,
  isUpdating: false,
  fulfillment: null,
}

const store = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORE.GET_STORE: {
      const { payload } = action
      return {
        ...state,
        store: payload,
        error: null,
      }
    }
    case STORE.GET_MENUS: {
      const { payload } = action
      return {
        ...state,
        menus: payload,
      }
    }

    case STORE.GET_ITEM: {
      const { payload } = action
      return {
        ...state,
        item: payload,
        itemError: null,
      }
    }

    case STORE.GET_ITEM_ERROR: {
      const { payload } = action
      return {
        ...state,
        item: null,
        itemError: payload,
      }
    }
    case STORE.IS_UPDATING: {
      const { payload } = action
      return {
        ...state,
        isUpdating: payload,
      }
    }
    case STORE.UPDATE_FULFILLMENT: {
      const { payload } = action
      return {
        ...state,
        fulfillment: payload,
      }
    }

    default: {
      return state
    }
  }
}

export default store
