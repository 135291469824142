import React, { FC } from 'react'
import { ButtonGroup, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Action, Fulfillment } from 'types/store'
import useStyles from './style'

interface ChooseProps {
  actions: Action[]
  onHandler: (t: any) => void
  type: Fulfillment | null
}
const ChooseType: FC<ChooseProps> = ({ actions, onHandler, type }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleClick = (typeFul: any) => {
    if (onHandler) onHandler(typeFul)
  }

  return (
    <ButtonGroup fullWidth={isMobile}>
      {actions &&
        actions.length > 0 &&
        actions.map((act) => {
          const isActive = type === act.fulfillment
          return (
            <Button
              key={act.item}
              size={isMobile ? 'small' : 'large'}
              className={classes.container}
              variant={isActive ? 'contained' : 'outlined'}
              onClick={() => handleClick(act.fulfillment)}>
              <Typography noWrap color={isActive ? 'white' : ''} variant="h6">
                {act.item}
              </Typography>
            </Button>
          )
        })}
    </ButtonGroup>
  )
}

export default ChooseType
