import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    borderBottom: '1px solid #edebeb',
  },
  price: {
    flex: 1,
    padding: '8px 5px 0 5px',
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 5,
    gap: 5,
  },
  counter: {
    flex: 1,
  },
}))

export default useStyles
