import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { ActionsSupport, Fulfillment } from 'types/store'
import { Information, ChooseType } from './components'

import useStyles from './style'

interface HeaderProps {
  information?: ActionsSupport | null
  fulfillment: Fulfillment | null
  onHandler: (t: Fulfillment) => void
  showInformation?: boolean
}

const Header: FC<HeaderProps> = ({
  information = null,
  fulfillment,
  onHandler,
  showInformation = true,
}) => {
  if (!information) return null
  const { times, actions } = information
  const classes = useStyles()
  const RenderInfo = (size: string) => {
    let hidden = { xs: 'none', xl: 'flex' }
    if (size === 'xl') {
      hidden = { xl: 'none', xs: 'flex' }
    }
    if (!showInformation) {
      return null
    }
    return (
      <Grid item xs={12} md={12} lg={12} xl={9} className={classes.times} sx={{ display: hidden }}>
        {times && <Information times={times} fulfillment={fulfillment} />}
      </Grid>
    )
  }
  return (
    <Grid spacing={1} className={classes.container} container>
      {RenderInfo('xs')}
      <Grid item xs={12} md={12} lg={12} xl={showInformation ? 3 : 12} className={classes.actions}>
        {actions && <ChooseType type={fulfillment} onHandler={onHandler} actions={actions} />}
      </Grid>
      {RenderInfo('xl')}
    </Grid>
  )
}

export default Header
