import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {},
  ddcontainer: {},
  loading: {
    background: '#e6e6e6',
    padding: 10,
    fontFamily: 'Roboto',
  },
  item: {
    padding: 10,
    display: 'block',
    background: '#e6e6e6',
    fontFamily: 'Roboto',
  },
  active: {
    background: '#ccc',
    cursor: 'pointer',
  },
}))

export default useStyles
