import React from 'react'
import { Grid, Typography } from '@mui/material'

import useStyles from './style'

const RenderHeader = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid item xs={5} md={7}>
        <Typography variant="h4" color="SlateGray">
          Name
        </Typography>
      </Grid>
      <Grid item xs={4} md={3}>
        <Typography variant="h4" color="SlateGray">
          Quantity
        </Typography>
      </Grid>
      <Grid item xs={3} md={2}>
        <Typography variant="h4" color="SlateGray">
          Price
        </Typography>
      </Grid>
    </div>
  )
}

export default RenderHeader
