export function formatPrice(num, decimals = 2) {
  if (num || num === 0) {
    return num.toFixed(decimals)
  }
  return null
}

export function formatCurrency(price, money = '$') {
  return `${money}${price}`
}

export const formatPriceCurrency = (number, currency = '$', decimals = 2) => {
  return formatCurrency(formatPrice(number, decimals), currency)
}

export const isOnlyNumbers = (data: string) => {
  const pattern = /^[0-9]*$/
  return pattern.test(data)
}

export const isPhoneNumbers = (data: string) => {
  const pattern = /^((\+)[0-9]*$|[0-9]*$)/
  return pattern.test(data)
}
