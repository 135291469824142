import Base from './modules/base'

class CartService extends Base {
  postOrder(data: any) {
    const url = '/v2/olo/order'
    return this.put(url, data)
  }

  validPromoCode(data: any) {
    const url = '/v2/olo/order/validate_promo_code'
    return this.post(url, data)
  }

  validAddress(data: any) {
    const { lat, lon, store_id } = data
    const url = `/v2/olo/order/validate_delivery_distance/${store_id}/${lat}/${lon}`

    return this.post(url, data)
  }
}

export default new CartService()
