import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 10,
  },
  date: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  cvc: {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}))

export default useStyles
