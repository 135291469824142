import { useSelector, useDispatch } from 'react-redux'
import { showSnackbar, hideSnackbar } from 'actions'
import { selectSnackbar } from 'selectors/app'

const HIDE_TIME_SB = 4000

export default function useSnackbar() {
  const snackbar = useSelector(selectSnackbar)
  const dispatch = useDispatch()

  const showError = (msg: string, type = 'error', timeout = HIDE_TIME_SB) => {
    dispatch(showSnackbar(msg, type))
    setTimeout(() => {
      dispatch(hideSnackbar())
    }, timeout)
  }

  const hideError = () => {
    dispatch(hideSnackbar())
  }

  return {
    snackbar,
    showMessage: showError,
    hideMessage: hideError,
  }
}
