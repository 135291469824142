import React, { FC } from 'react'
import clsx from 'clsx'
import Star from '@mui/icons-material/Star'
import { Typography } from '@mui/material'

import useStyles from './style'

interface TitleProps {
  data: any
}

const Icons = {
  star: Star,
}

const Title: FC<TitleProps> = ({ data }) => {
  const classes = useStyles()
  const { title, items, description } = data

  return (
    <div className={classes.title}>
      <div className={classes.sizeTitle}>{title}</div>
      <Typography variant="body2" gutterBottom>
        {description}
      </Typography>
      <div className={classes.items}>
        {items &&
          items.length > 0 &&
          items.map((info, i) => {
            if (!info?.item) {
              return null
            }
            if (typeof info?.item !== 'string') {
              const Icon = Icons[info?.item?.icon]
              return (
                <span
                  key={info.item.rating}
                  className={clsx(classes.text, classes.icons, i > 0 && classes.withDot)}>
                  <span>{info?.item?.rating}</span>
                  {Icon && (
                    <span>
                      <Icon sx={{ fontSize: 16 }} />
                    </span>
                  )}
                  <span>({info?.item?.ratingQty} ratings)</span>
                </span>
              )
            }
            return (
              <span key={info.item} className={clsx(classes.text, i > 0 && classes.withDot)}>
                {info?.item}
              </span>
            )
          })}
      </div>
    </div>
  )
}

export default Title
