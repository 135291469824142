import { formatCurrency, formatPrice } from 'utils/helper'
import { ItemInfo } from 'components/ItemCard/ItemCard'

export const getInfoItem = (item: any) => {
  const info: ItemInfo[] = []

  if (item?.modifiers && item.modifiers.length > 0) {
    item.modifiers.forEach((mod) => {
      const modName = mod.name
      if (mod?.option_list && mod.option_list.length > 0) {
        mod.option_list.forEach((opt) => {
          const valueFormat = formatCurrency(formatPrice(opt.value))
          info.push({
            name: modName,
            value: `${opt.name} (${valueFormat})`,
          })
        })
      }
    })
  }

  if (item?.special_instructions !== '') {
    info.push({ name: '', value: item?.special_instructions })
  }

  return info
}
