import { SESSION } from 'constants/ActionTypes'

const initialState = {
  loggedIn: false,
  user: null,
  token: null,
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        user: action.user,
      }
    }
    case SESSION.SESSION_SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      }
    }

    case SESSION.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null,
      }
    }
    case SESSION.ERROR_LOGIN: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST',
        },
        errorLogin: true,
      }
    }

    default: {
      return state
    }
  }
}

export default sessionReducer
