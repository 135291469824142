import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 420,
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
  },
  media: {
    padding: theme.spacing(1, 2),
    textAlign: 'center',
    '& > img': {
      width: 'auto',
      maxWidth: '100%',
    },
  },
  content: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 2, 1),
  },
  link: {
    textDecoration: 'underline',
  },
}))

export default useStyles
