import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import { makeStyles } from '@mui/styles'
import { Container, LinearProgress, Theme } from '@mui/material'
import { Switch } from 'react-router-dom'
import RouteWithSubRoutes from 'utils/subRoutes'
import { TopBar } from '../Dashboard/components'
// import { Redirect } from 'react-router-dom'
// import { Snackbar } from 'components'
// import { useSession, useSnackbar } from 'hooks'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
}))

const Auth = (props) => {
  const { routes } = props
  const classes = useStyles()
  // const { loggedIn } = useSession()
  // const { snackbar } = useSnackbar()

  return (
    <>
      <TopBar handleLogout={() => null} onOpenNavBarMobile={() => null} />
      <main className={classes.content}>
        {/* <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense> */}
        <Container>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              {routes.length > 0 &&
                routes.map((route) => <RouteWithSubRoutes key={route.path} {...route} />)}
            </Switch>
          </Suspense>
        </Container>
      </main>
    </>
  )
}

export default Auth
