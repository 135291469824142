import React, { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { AppBar, Button, Toolbar } from '@mui/material'

import { ShoppingCart } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { selectColor, selectStore } from 'selectors/store'
import { TitleTopCard } from 'components'
import { getRemoteCart } from 'selectors/cart'
import useRouter from 'utils/useRouter'

import useStyles from './style'

interface TopBarProps {
  onOpenNavBarMobile: () => void
  className?: any
  handleLogout: () => void
  refElem?: any
}

const TopBar: FC<TopBarProps> = ({ onOpenNavBarMobile, className, handleLogout, refElem }) => {
  const classes = useStyles()
  const storeSelected = useSelector(selectStore)
  const cartRemote = useSelector(getRemoteCart)
  const storeColor = useSelector(selectColor)
  const countItems = cartRemote?.itemsOrdered.length || 0
  const router = useRouter()
  const storePath = router.location.pathname.split('/')[1]

  let bgHeader = { backgroundColor: '#4fc5cf' }
  if (storeColor) {
    bgHeader = { backgroundColor: `${storeColor}` }
  }

  return (
    <AppBar className={clsx(classes.root, className)} ref={refElem}>
      <div style={bgHeader}>
        <Toolbar>
          <RouterLink to={`/${storePath}`}>
            {storeSelected && (
              <TitleTopCard avatar={storeSelected.avatar} title={storeSelected.name} />
            )}
          </RouterLink>
          <div className={classes.flexGrow} />
          <Button
            variant="contained"
            sx={{ display: { lg: 'none', xs: 'flex' } }}
            onClick={onOpenNavBarMobile}
            startIcon={<ShoppingCart />}>
            {countItems}
          </Button>
          {cartRemote && countItems > 0 && (
            <Button
              sx={{ display: { md: 'flex', xs: 'none' } }}
              variant="contained"
              startIcon={<ShoppingCart />}>
              {countItems}
            </Button>
          )}
        </Toolbar>
      </div>
    </AppBar>
  )
}

export default TopBar
