import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px 20px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 8px 20px 0',
    },
  },
}))

export default useStyles
