export const parseMenus = (data: any) => {
  if (!data) return null

  const categories = data?.categories
  const notExistPopular = categories.find((t: any) => t.name === 'Popular')
  const notExistFeature = categories.find((t: any) => t.name === "Customers' Favorites")

  if (data?.popular_items && data?.popular_items.length > 0 && !notExistPopular) {
    categories.unshift({
      id: 0,
      name: 'Popular',
      items: data?.popular_items,
      menu_id: null,
      description: '',
    })
  }
  if (data?.featured_items && data?.featured_items.length > 0 && !notExistFeature) {
    categories.unshift({
      id: 1,
      name: "Customers' Favorites",
      items: data?.featured_items,
      menu_id: null,
      description: '',
    })
  }

  return categories
}
