import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: 10,
    gap: 5,
    flexDirection: 'column',
    paddingLeft: 25,
  },
}))

export default useStyles
