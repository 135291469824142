import { createSelector } from 'reselect'

const base = (state) => state.cart
export const hasCart = createSelector(base, (b) => b?.cart?.store_id)
export const getCart = createSelector(base, (b) => b.cart)
export const getRemoteCart = createSelector(base, (b) => b.cartRemote)

export const getItemMenu = (menus: any, itemId: number) => {
  if (menus?.length > 0) {
    for (let index = 0; index < menus.length; index += 1) {
      const menu = menus[index]
      const menuVal = menu.items.find((val: any) => val.id === itemId)

      if (menuVal) {
        return menuVal
      }
    }
  }
  return null
}

export const getBodyPost = createSelector(base, (b) => b.body)
export const selectUpdatingCart = createSelector(base, (b) => b.isUpdating)
/**
 * @return if currently is executing an order
 */
export const selectOrdering = createSelector(base, (b) => b.isOrdering)

export const getPreviousCheckOutData = createSelector(base, (b) => b.checkoutFields)
export const getScheduled = createSelector(base, (b) => b.schedule)
