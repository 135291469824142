import React, { FC, useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface SnackbarProp {
  message: string
  open: boolean
  severity: AlertColor
  onClose?: () => void
}

const CustomizedSnackbars: FC<SnackbarProp> = ({ message, open, onClose, severity = 'error' }) => {
  const [openLocal, setOpenLocal] = useState(open)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    onClose?.()
    setOpenLocal(false)
  }

  useEffect(() => {
    setOpenLocal(open)
  }, [open])

  return (
    <Snackbar
      open={openLocal}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      // autoHideDuration={6000}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomizedSnackbars
