import React, { FC, useState, useEffect } from 'react'
import { Button, ButtonGroup, TextField, Typography } from '@mui/material'
import { Fulfillment } from 'types/store'
import { useSelector, useDispatch } from 'react-redux'
import { selectFulfillment } from 'selectors/store'
import { getRemoteCart, selectOrdering } from 'selectors/cart'
import { formatPrice } from 'utils/helper'
import { ICartRemote } from 'types/cart'
import { updateFulfillment } from 'actions'

import useStyles from './style'

const percents = {
  PICKUP: [0, 5, 10, 'Other'],
  CURBSIDE: [5, 10, 15, 'Other'],
  DINEIN: [5, 10, 15, 'Other'],
  DELIVERY: [15, 20, 25, 'Other'],
}
const defaultPercent = {
  PICKUP: 5,
  CURBSIDE: 10,
  DINEIN: 10,
  DELIVERY: 20,
}

interface ITip {
  onUpdate: (v: number | string) => void
}
const Tip: FC<ITip> = ({ onUpdate }) => {
  const dispatch = useDispatch()
  const isOrdering = useSelector(selectOrdering)
  const fulfillment: Fulfillment = useSelector(selectFulfillment)
  const cartRemote: ICartRemote = useSelector(getRemoteCart)

  const classes = useStyles()
  const values = percents[fulfillment]
  const [tipValue, setTipValue] = useState<string | number | null>(defaultPercent[fulfillment])
  const [customValue, setCustomValue] = useState<string | number>('')

  const setValue = (value) => {
    setTipValue(value)
    let tipPlus = 0
    if (value !== 'Other') {
      tipPlus = formatPrice(cartRemote?.price_before_taxes * (value / 100))
    }
    onUpdate(tipPlus)
  }

  useEffect(() => {
    if (cartRemote && tipValue && tipValue !== 'Other') {
      const rnum: any = tipValue
      onUpdate(formatPrice(cartRemote?.price_before_taxes * (rnum / 100)))
    }
  }, [tipValue])

  let filterTimeout
  const doNumberFilter = (query: number) => {
    clearTimeout(filterTimeout)
    if (!query) return setCustomValue('')

    filterTimeout = setTimeout(() => {
      setCustomValue(query)
    }, 500)
  }

  const handleChange = (event) => {
    let tipText = event.target.value
    const numbers = /^[0-9.]+$/
    if (!tipText.match(numbers)) {
      return
    }

    if (tipText <= 0) {
      tipText = 0
    }
    doNumberFilter(tipText)
    // onUpdate(tipText)
  }

  useEffect(() => {
    if (cartRemote?.fulfillment && !fulfillment) {
      dispatch(updateFulfillment(cartRemote?.fulfillment))
    }
  }, [cartRemote])

  useEffect(() => {
    setTipValue(defaultPercent[fulfillment])
  }, [fulfillment])

  useEffect(() => {
    if (customValue !== '') {
      onUpdate(customValue)
    }
  }, [customValue])

  return (
    <div className={classes.container}>
      <div className={classes.normalTip}>
        <div className={classes.contentInfo}>
          <Typography variant="h5">
            {fulfillment !== 'DELIVERY' ? 'Tip the staff' : 'Tip the driver'}
          </Typography>
          <Typography variant="body2">(Optional)</Typography>
        </div>
        <div>
          <ButtonGroup aria-label="outlined primary button group">
            {values &&
              values.length > 0 &&
              values.map((value) => {
                return (
                  <Button
                    key={value}
                    onClick={() => setValue(value)}
                    disabled={isOrdering}
                    variant={value === tipValue ? 'contained' : 'outlined'}>
                    {value}
                    {value !== 'Other' && '%'}
                  </Button>
                )
              })}
          </ButtonGroup>
        </div>
      </div>

      {tipValue === 'Other' && (
        <TextField
          id="custom-tip"
          placeholder="Custom tip. e.g. 2.5"
          type="text"
          fullWidth
          onChange={handleChange}
          disabled={isOrdering}
        />
      )}
    </div>
  )
}
export default Tip
