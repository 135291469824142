import { createSelector } from 'reselect'
import { Action, InfoType, Message } from 'types/store'
import { parseMenus } from './helpers/parseMenus'

const base = (state) => state.store
export const selectStore = createSelector(base, (b) => b.store)
export const selectMenus = createSelector(base, (b) => parseMenus(b.menus))
export const selectMenuCategories = createSelector(base, (b) => b?.menus?.categories)
export const selectItem = createSelector(base, (b) => {
  return {
    error: b.itemError,
    item: b.item,
  }
})

// export const selectCheckinTutorialVisible = createSelector(base, b => b.checkinTutorialVisible)
export const selectUpdatingStore = createSelector(base, (b) => b.isUpdating)
export const selectActions = createSelector(selectStore, (data) => {
  if (!data) {
    return null
  }

  const {
    support_curbside: curbside,
    support_delivery: delivery,
    support_dinein: dinein,
    support_pickup: pickup,
    distance,
    delivery_message: deliveryMessage,
    store_consumer_time: storeConsumerTime,
    delivery_time: deliveryTime,
    delivery_price: deliveryPrice,
    address,
  } = data

  const actions: Action[] = []
  const times: InfoType = {
    PICKUP: undefined,
    DINEIN: undefined,
    CURBSIDE: undefined,
    DELIVERY: undefined,
  }

  if (pickup) {
    actions.push({ item: 'Pick Up', fulfillment: 'PICKUP' })
    const m: Message[] = [
      { label: 'Ready Time', info: `${storeConsumerTime} min` },
      { label: 'Pickup your order at', info: address?.address_print },
    ]
    times.PICKUP = m
  }
  if (dinein) {
    actions.push({ item: 'Dine In', fulfillment: 'DINEIN' })
    const m: Message[] = [
      { label: 'Food Ready in', info: `${storeConsumerTime} min` },
      { label: 'Pickup your order at', info: address?.address_print },
    ]
    times.DINEIN = m
  }
  if (curbside) {
    actions.push({ item: 'Curbside', fulfillment: 'CURBSIDE' })
    const m: Message[] = [
      { label: 'Ready Time', info: `${storeConsumerTime} min` },
      { label: 'Pickup your order at', info: address?.address_print },
    ]
    times.CURBSIDE = m
  }
  if (delivery) {
    actions.push({ item: 'Delivery', fulfillment: 'DELIVERY' })
    if (deliveryMessage) {
      times.DELIVERY = [{ label: deliveryMessage, info: null }]
    } else {
      const m: Message[] = [
        { label: "We'll get your delivery address during checkout", info: '' },
        // { label: 'Delivery Time', info: deliveryTime ? `${deliveryTime} min` : '-' },
        // { label: 'Delivery Fee', info: deliveryPrice ? formatPriceCurrency(deliveryPrice) : '-' },
      ]
      times.DELIVERY = m
    }
  }

  return {
    actions,
    times,
  }
})

export const selectFulfillment = createSelector(base, (b) => b.fulfillment)

export const statusStore = createSelector(selectStore, (b) => {
  return {
    open: b?.receiving_orders,
    status: b?.current_status,
    message: b?.warning_message,
  }
})

export const selectColor = createSelector(selectStore, (b) => b?.colour)
