import StoreService from 'services/store'
import CartService from 'services/cart'
import { CART } from 'constants/ActionTypes'
import { STORE_CART, STORE_UIID_CART } from 'constants/localStorage'
import store from 'store'

export const addCart = (cartData: any) => async (dispatch) => {
  try {
    dispatch({
      type: CART.ADD_CART,
      payload: cartData,
    })
    /* const { data } = await StoreService.postCart(cartData)
    dispatch({
      type: CART.UPDATE_CART_REMOTE,
      payload: data,
    }) */
  } catch (err: any) {
    console.log(err)
  }
}

const updateCartStatusLocal = (dispatch, status: boolean) => {
  dispatch({
    type: CART.IS_UPDATING,
    payload: status,
  })
}

const updateCartRemote = (dispatch, data) => {
  dispatch({
    type: CART.UPDATE_CART_REMOTE,
    payload: data,
  })
}
export const postCartRemote = (cartData: any) => async (dispatch) => {
  if (cartData) {
    updateCartStatusLocal(dispatch, true)
    const newCartdata = { ...cartData }
    const localStorageCartData = { ...cartData }
    try {
      const st = store.getState().cart.status
      if (newCartdata?.itemsOrdered.length > 0 && st !== 'loading') {
        dispatch({
          type: CART.STATUS,
          payload: 'loading',
        })
        if (
          newCartdata?.fulfillment !== 'DELIVERY' ||
          (newCartdata?.fulfillment === 'DELIVERY' && !newCartdata.delivery_address?.lat)
        ) {
          delete newCartdata.delivery_address
        }
        const { data } = await StoreService.postCart(newCartdata)
        updateCartRemote(dispatch, data)
      }
      if (localStorageCartData?.fulfillment !== 'DELIVERY') {
        delete localStorageCartData.delivery_address
      }
      localStorage.setItem(STORE_CART, JSON.stringify(localStorageCartData))
      updateCartStatusLocal(dispatch, false)
    } catch (err: any) {
      console.log(err)
      updateCartStatusLocal(dispatch, false)
    }
  }
}

// local cart
export const addItemsToCart = (item: any) => (dispatch) => {
  try {
    // const { data } = await StoreService.postCart(cartData)
    dispatch({
      type: CART.ADD_ITEM_ORDERED,
      payload: item,
    })
  } catch (err: any) {
    console.log('err', err)
  }
}

const orderingCart = (dispatch, payload: boolean) => {
  dispatch({
    type: CART.IS_ORDERING,
    payload,
  })
}

export const makingOrder =
  (st = true) =>
  (dispatch) =>
    orderingCart(dispatch, st)

export const createOrder = (orderData: any) => async (dispatch) => {
  orderingCart(dispatch, true)
  const newOrderData = { ...orderData }
  if (orderData?.orderRequest?.fulfillment !== 'DELIVERY') {
    delete newOrderData.delivery_address
  }
  try {
    return CartService.postOrder(newOrderData)
    // orderingCart(dispatch, false)
    /* return new Promise(function (resolve, reject) {
      resolve(data)
    }) */
    // TODO: redirect to thanks
  } catch (err: any) {
    console.log('--err---')
    console.log(err)
    orderingCart(dispatch, false)
  }
}
export const updateBody = (body: any) => (dispatch) => {
  dispatch({
    type: CART.UPDATE_BODY,
    payload: body,
  })
}

export const updateCartQty = (qty: number, index: number) => (dispatch) => {
  dispatch({
    payload: qty,
    index,
    type: CART.UPDATE_QTY_ITEM,
  })
}

export const deleteItemFromCart = (index: number) => (dispatch) => {
  dispatch({
    payload: index,
    type: CART.DELETE_ITEM,
  })
}

export const updateFieldCart = (field: string, value: any) => (dispatch) => {
  if (field === 'fulfillment' && value === 'DELIVERY') {
    dispatch({
      field: 'delivery_address',
      value: {},
      type: CART.ADD_FIELD,
    })
  } else if (field === 'fulfillment') {
    dispatch({
      field: 'delivery_address',
      type: CART.REMOVE_FIELD,
    })
  }
  dispatch({
    field,
    value,
    type: CART.UPDATE_A_FIELD,
  })
}

export const updateCartStatus = (st: boolean) => (dispatch) => {
  updateCartStatusLocal(dispatch, st)
}

export const validPromoCode = (body: any) => (dispatch) => {
  return CartService.validPromoCode(body)
}

export const cleanCartRemote = () => (dispatch) => {
  updateCartRemote(dispatch, null)
}

export const cleanAfterOrder = () => (dispatch) => {
  updateCartRemote(dispatch, null)
  /* dispatch({
    type: CART.UPDATE_BODY,
    payload: null,
  }) */
  localStorage.removeItem(STORE_CART)
  localStorage.removeItem(STORE_UIID_CART)
  dispatch({
    type: CART.CLEAN_CART,
  })
}

export const validAddress = (body: any) => (dispatch) => {
  updateCartStatusLocal(dispatch, true)
  // orderingCart(dispatch, true)
  try {
    return CartService.validAddress(body)
  } catch (err: any) {
    console.log('new Error')
  } finally {
    updateCartStatusLocal(dispatch, false)
    // orderingCart(dispatch, false)
  }
}
export const saveCheckoutField = (fields: any) => (dispatch) => {
  dispatch({
    type: CART.CHECKOUT_FIELD,
    payload: fields,
  })
}

export const updateScheduleSelected = (body: any) => (dispatch) => {
  dispatch({
    type: CART.UPDATE_SCHEDULE_FIELD,
    payload: body,
  })
}
