import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: 'gray 1px solid',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerSelect: {
    backgroundColor: 'GhostWhite',
    margin: '0 0 35px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    margin: 16,
  },
  title: {
    paddingLeft: 10,
  },
  step: {
    color: 'black',
    display: 'flex',
    border: '1px solid black',
    paddingRight: 5,
    paddingLeft: 5,
    borderRadius: '50%',
  },
  stepOnSelect: {
    backgroundColor: 'black',
    color: 'white',
  },
  stepInSelect: {
    color: 'black',
  },
  action: {
    paddingRight: 10,
  },
}))

export default useStyles
