export const SESSION = {
  SESSION_LOGIN: 'SESSION/SESSION_LOGIN',
  SESSION_SET_TOKEN: 'SESSION/SESSION_SET_TOKEN',
  SESSION_LOGOUT: 'SESSION/SESSION_LOGOUT',
  ERROR_LOGIN: 'SESSION/ERROR_LOGIN',
}

export const APP = {
  SET_SNACKBAR: 'APP/SET_SNACKBAR',
  SHOW_SNACKBAR: 'APP/SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'APP/HIDE_SNACKBAR',
  SHOW_LOADING: 'APP/SHOW_LOADING',
  HIDE_LOADING: 'APP/HIDE_LOADING',
}

export const STORE = {
  GET_STORE: 'STORE/GET_STORE',
  GET_MENUS: 'STORE/GET_MENUS',
  GET_ITEM: 'STORE/GET_ITEM',
  GET_ITEM_ERROR: 'STORE/GET_ITEM_ERROR',
  IS_UPDATING: 'STORE/IS_UPDATING',
  UPDATE_FULFILLMENT: 'STORE/UPDATE_FULFILLMENT',
}

export const CART = {
  ADD_CART: 'CART/ADD_CART',
  ADD_ITEM_ORDERED: 'CART/ADD_ITEM_ORDERED',
  UPDATE_CART_REMOTE: 'CART/UPDATE_CART_REMOTE',
  STATUS: 'CART/STATUS',
  CLEAN_CART: 'CART/CLEAN_CART',
  UPDATE_BODY: 'CART/UPDATE_BODY',
  UPDATE_QTY_ITEM: 'CART/UPDATE_QTY_ITEM',
  IS_UPDATING: 'CART/IS_UPDATING',
  DELETE_ITEM: 'CART/DELETE_ITEM',
  UPDATE_A_FIELD: 'CART/UPDATE_A_FIELD',
  ADD_FIELD: 'CART/ADD_FIELD',
  REMOVE_FIELD: 'CART/REMOVE_FIELD',
  IS_ORDERING: 'CART/IS_ORDERING',
  CHECKOUT_FIELD: 'CART/CHECKOUT_FIELD',
  UPDATE_SCHEDULE_FIELD: 'CART/UPDATE_SCHEDULE_FIELD',
}
