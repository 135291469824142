import { Theme, colors } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  modifiers: {
    backgroundColor: '#f5f5f5',
    padding: 10,
    marginBottom: 20,
  },
  modifierHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export default useStyles
