import React, { useState, FC, useEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Snackbar, TextField } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { getCart, getRemoteCart, selectOrdering, selectUpdatingCart } from 'selectors/cart'
import { useDispatch, useSelector } from 'react-redux'
import { updateCartStatus, updateFieldCart, validPromoCode } from 'actions'
import { useSnackbar } from 'hooks'

import useStyles from './style'

interface IPromoCode {
  code: string
  id: number
  message: string
  term: string
}
export interface IBodyPromoCode {
  store_id: number | null // eslint-disable-line
  promo_code: string // eslint-disable-line
  purchase_amount: number | null // eslint-disable-line
}

const PromoCode: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const cartRemote = useSelector(getRemoteCart)
  const isOrdering = useSelector(selectOrdering)
  const { store_id: storeId } = useSelector(getCart)
  const [message, setMessage] = useState<IPromoCode | null>(null)
  const { showMessage } = useSnackbar()
  const [body, setBody] = useState<IBodyPromoCode>({
    store_id: storeId || null,
    promo_code: '',
    purchase_amount: cartRemote?.price_before_taxes,
  })
  const updating = useSelector(selectUpdatingCart)
  let timeOut: any | null = null

  const onChangeHandle = (event) => {
    setBody((prev) => ({
      ...prev,
      promo_code: event.target.value,
    }))
  }

  useEffect(() => {
    setBody((prev) => ({
      ...prev,
      purchase_amount: cartRemote?.price_before_taxes,
      store_id: storeId,
    }))

    return () => {
      if (timeOut !== null) {
        clearTimeout(timeOut)
      }
    }
  }, [cartRemote, storeId])

  const hideMessage = () => {
    timeOut = setTimeout(() => {
      setMessage(null)
    }, 4000)
  }

  const onValidCode = async () => {
    dispatch(updateCartStatus(true))
    try {
      const promo: any = await dispatch(validPromoCode(body))

      if (promo?.data?.message === '') {
        dispatch(updateFieldCart('promo', promo?.data?.code))
        showMessage('Promo code was applied succsessfull!', 'success')
      } else {
        setMessage(promo?.data)
        hideMessage()
      }
      dispatch(updateCartStatus(false))
    } catch (error) {
      setMessage({
        id: 0,
        message: 'This promo code not exists!',
        code: '0',
        term: '',
      })
      hideMessage()
      dispatch(updateCartStatus(false))
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.forms}>
        <TextField
          placeholder="Promo Code"
          value={body.promo_code}
          fullWidth
          onChange={onChangeHandle}
          disabled={isOrdering}
        />
        <LoadingButton
          disabled={body.promo_code === '' || isOrdering}
          endIcon={<CheckCircleOutline />}
          variant="contained"
          loading={updating}
          onClick={onValidCode}>
          Valid
        </LoadingButton>
      </div>
      {message && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {message?.message}
        </Alert>
      )}
    </div>
  )
}

export default PromoCode
