import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  close: {
    display: 'flex',
    paddingLeft: 16,
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    padding: 10,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0px 15px',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    flexDirection: 'column',
    gap: 10,
  },
  img: {
    marginBottom: 20,
  },
  warning: {
    marginBottom: 20,
  },
}))

export default useStyles
