import axios from 'axios'

const restClient = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_NODE_ENV,
  timeout: 10000,
})

/* restClient.interceptors.request.use(
  async (request) => {
    const { token = null } = store.getState().session
    request.headers['X-Access-Token'] = token
    return request
  },
  (err) => Promise.reject(err)
) */

restClient.interceptors.response.use(async (response) => response)

export default restClient
