import React, { Suspense } from 'react'
import { makeStyles } from '@mui/styles'
import { LinearProgress } from '@mui/material'
import { Switch } from 'react-router-dom'
import RouteWithSubRoutes from 'utils/subRoutes'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const Error = (props) => {
  const { routes } = props
  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          {routes.map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
        </Switch>
      </Suspense>
    </main>
  )
}

export default Error
