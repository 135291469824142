import React, { useState, FC } from 'react'
import { Button, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import moment from 'moment'
import WatchIcon from '@mui/icons-material/Watch'
import useStyles from './style'

type Detail = {
  from: string
  to: string
}

type Day = {
  day: string
  open_hours: Detail[] // eslint-disable-line
}
interface ScheduleProp {
  timeTable: Day[]
}

const Schedule: FC<ScheduleProp> = ({ timeTable }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!timeTable || timeTable.length === 0) {
    return null
  }

  const convertPM = (hour: string) => moment(hour, ['HH.mm']).format('hh:mm A')
  const dayToday = moment().format('dddd')
  const hoursToday = timeTable.filter((t: Day) => t.day === dayToday.toLowerCase())

  const renderDay = (time: Day) => {
    const d = time?.day
    return (
      <div className={classes.date}>
        <div className={classes.day}>{d}:</div>
        <div className={classes.hours}>
          {time.open_hours.length > 0 &&
            time.open_hours.map((op: Detail) => {
              return (
                <Typography key={`${d}${op.from}${op.to}`}>
                  {convertPM(op.from)} - {convertPM(op.to)}
                </Typography>
              )
            })}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.schedule}>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="large"
        fullWidth={isMobile}
        startIcon={<WatchIcon />}
        onClick={handleClick}>
        <div className={classes.date}>
          <div className={classes.day}>Today:</div>
          <div className={classes.hoursButton}>
            {hoursToday &&
              hoursToday[0]?.open_hours.map((h: Detail) => {
                return (
                  <div key={`today${h.from}${h.to}`}>
                    {convertPM(h.from)} - {convertPM(h.to)}
                  </div>
                )
              })}
          </div>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {timeTable.length > 0 &&
          timeTable.map((time: Day) => {
            return (
              <MenuItem key={time.day} onClick={handleClose}>
                {renderDay(time)}
              </MenuItem>
            )
          })}
      </Menu>
    </div>
  )
}

export default Schedule
