import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Paper, Typography, Link, Button } from '@mui/material'
import useStyles from './style'

const CookiesNotification = () => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    const consent = Cookies.get('consent')

    if (!consent) {
      setOpen(true)
    }
  }, [])

  const handleClose = () => {
    Cookies.set('consent', 'true')
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <Paper className={classes.root} elevation={3}>
      <div className={classes.media}>
        <img alt="Cookies" src="/images/lovvetDirect.png" />
      </div>
      <div className={classes.content}>
        <Typography variant="body1">
          We use Cookies to ensure that we give you the best experience on our website. Read our{' '}
          <Link
            className={classes.link}
            component="a"
            href="https://www.lovvett.com/hubfs/Legal/LOVVETT_Privacy-Policy_V1.pdf"
            rel="noopener"
            underline="always"
            target="_blank">
            Privacy Policy
          </Link>
          .
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button color="primary" onClick={handleClose} variant="contained">
          I Agree
        </Button>
      </div>
    </Paper>
  )
}

export default CookiesNotification
