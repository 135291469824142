import StoreService from 'services/store'
import { STORE } from 'constants/ActionTypes'
import { Fulfillment, IQueryStore } from 'types/store'

export const getStore = (storeName: string) => async (dispatch) => {
  try {
    const dateNow = new Date()
    const query: IQueryStore = {
      key: storeName,
      day: dateNow.getDay() === 0 ? 7 : dateNow.getDay(),
      hours: dateNow.getHours(),
      minutes: dateNow.getMinutes(),
    }
    const { data } = await StoreService.getStore(query)
    dispatch({
      type: STORE.GET_STORE,
      payload: data,
    })
  } catch (err: any) {
    console.log('---err')
    console.log(err?.response)
    if (err?.response && err?.response?.status === 404) {
      location.href = '/errors/error-404' // eslint-disable-line
    }
  }
}
export const getMenus = (id: number, type: Fulfillment | null) => async (dispatch) => {
  try {
    const { data } = await StoreService.getMenus(id, type)
    dispatch({
      type: STORE.GET_MENUS,
      payload: data,
    })
  } catch (err: any) {
    console.log('ERROR get menu')
  }
}

export const getItem =
  (itemId: number, storeId: number, type: Fulfillment | null) => async (dispatch) => {
    dispatch({
      type: STORE.IS_UPDATING,
      payload: true,
    })
    try {
      const { data } = await StoreService.getItem(itemId, storeId, type)
      dispatch({
        type: STORE.IS_UPDATING,
        payload: false,
      })
      dispatch({
        type: STORE.GET_ITEM,
        payload: data,
      })
    } catch (err: any) {
      dispatch({
        type: STORE.GET_ITEM_ERROR,
        payload: err,
      })
    }
  }

export const cleanItem = () => (dispatch) => {
  dispatch({
    type: STORE.GET_ITEM,
    payload: null,
  })
}

export const isUpdatingItem = (payload: boolean) => (dispatch) => {
  dispatch({
    type: STORE.IS_UPDATING,
    payload,
  })
}

export const updateFulfillment = (payload: Fulfillment) => (dispatch) => {
  localStorage.setItem('fulfillment', payload)
  dispatch({
    type: STORE.UPDATE_FULFILLMENT,
    payload,
  })
}
