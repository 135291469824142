import { APP } from 'constants/ActionTypes'

const INITIAL_STATE = {
  notifications: null,
  snackbar: {
    show: false,
    message: '',
    type: 'error',
  },
  showLoading: false,
}

const menus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP.SET_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          show: true,
          message: action.message,
          type: action.typesError,
        },
      }
    }
    case APP.HIDE_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          show: false,
        },
      }
    }
    case APP.SHOW_LOADING: {
      return {
        ...state,
        showLoading: true,
      }
    }
    case APP.HIDE_LOADING: {
      return {
        ...state,
        showLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default menus
