import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  schedule: {
    paddingBottom: 20,
    display: 'flex',
  },
  date: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  day: {
    display: 'flex',
    flex: 1,
    paddingRight: 20,
    textTransform: 'capitalize',
    fontWeight: 600,
  },
  hours: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  hoursButton: {
    fontWeight: 600,
  },
}))

export default useStyles
