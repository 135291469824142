import { colors } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
  },
  information: {
    display: 'flex',
    padding: 20,
    gap: 7,
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
    height: '100%',
    justifyContent: 'space-between',
  },
  topInfo: {
    display: 'flex',
    gap: 7,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  image: {
    marginLeft: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  prices: {
    display: 'flex',
    gap: 7,
  },
  price: {
    fontWeight: 'bold',
  },
  originalPrice: {
    textDecoration: 'line-through',
  },
  newPrice: {
    color: colors.red[500],
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
}))

export default useStyles
