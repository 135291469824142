import { APP } from 'constants/ActionTypes'

export const showSnackbar =
  (msg: string, typesError = 'error') =>
  (dispatch) =>
    dispatch({
      type: APP.SET_SNACKBAR,
      message: msg,
      typesError,
    })

export const hideSnackbar = () => (dispatch) => {
  dispatch({
    type: APP.HIDE_SNACKBAR,
  })
}
