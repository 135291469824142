export const initialValues = {
  cardNumber: '',
  month: '',
  year: '',
  cardCode: '',
}

export const initialTouched = {
  cardNumber: false,
  month: false,
  year: false,
  cardCode: false,
}

export default {
  initialValues,
  initialTouched,
}
