import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
    paddingLeft: 5,
  },
  times: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center',
    },
  },
}))

export default useStyles
