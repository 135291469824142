let init = {
  id: '', //
  street: '', //
  streetNumber: '', //
  zipCode: '',
  city: '', //
  state: '',
  stateCode: '', //
  county: '',
  countyCode: '',
  neighborhood: '',
  country: '',
  countryCode: '',
  printAddress: '', //
  lat: null,
  lon: null,
  active: true, //
  floor: '',
  building_name: '',
}
const obj = {
  street_number: 'streetNumber',
  route: 'street',
  postal_code: 'zipCode',
  locality: 'city',
  administrative_area_level_1: 'stateCode',
  country: 'country',
  neighborhood: 'neighborhood',
  administrative_area_level_2: 'countyCode',
}
const verifyLocat = (toSearch) => {
  const list = Object.keys(obj)
  for (let index = 0; index < list.length; index += 1) {
    if (toSearch.includes(list[index])) {
      return obj[list[index]]
    }
  }
  return null
}
const parseData = (data: any) => {
  if (!data) {
    return null
  }

  init = {
    ...init,
    printAddress: data?.formatted_address,
    id: data?.place_id,
    lat: data?.geometry?.location?.lat(),
    lon: data?.geometry?.location?.lng(),
  }

  data?.address_components.forEach((address) => {
    const k = verifyLocat(address?.types)
    if (k) {
      init = {
        ...init,
        [k]: address?.short_name,
      }
    }
  })
  return init
}

export default parseData
