import React, { FC } from 'react'
import { Typography, IconButton } from '@mui/material'
import Counter from 'components/Counter'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSelector } from 'react-redux'
import { selectOrdering } from 'selectors/cart'
import { formatPriceCurrency } from 'utils/helper'

import useStyles from './style'

export interface ItemInfo {
  name: string
  value: string
}

interface ItemProps {
  price: number
  qty: number
  info: ItemInfo[]
  onHandleDelete?: () => void | null
  onHandleQty?: (n: number) => void | null
  menu: any
}

const ItemCard: FC<ItemProps> = ({ menu, price, info, onHandleDelete, onHandleQty, qty }) => {
  const classes = useStyles()
  const isOrdering = useSelector(selectOrdering)
  const { lovvett_offer: lovvettOffer, lovvett_offer_left: lovvettLeft } = menu
  const handleDelete = () => {
    if (onHandleDelete) onHandleDelete()
  }

  const handleQty = (qtyUpdated: number) => {
    if (onHandleQty) onHandleQty(qtyUpdated)
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentInfo}>
        <Typography variant="h5">{menu.name}</Typography>
        {info?.length > 0 &&
          info.map((inf: ItemInfo) => {
            return (
              <Typography key={inf?.name} variant="body2">
                {inf.name ? `${inf.name} - ` : ''} {inf.value}
              </Typography>
            )
          })}
      </div>
      <div className={classes.counter}>
        <Counter
          count={qty}
          onUpdateQty={handleQty}
          disabled={isOrdering}
          disabledPlus={lovvettOffer && lovvettLeft === qty}
          limit={lovvettOffer ? lovvettLeft : null}
        />
      </div>
      <div className={classes.price}>
        <Typography variant="h5">{formatPriceCurrency(price)}</Typography>
      </div>
      <IconButton
        aria-label="delete"
        disabled={isOrdering}
        size="small"
        color="primary"
        onClick={handleDelete}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </div>
  )
}

ItemCard.defaultProps = {
  onHandleDelete: () => null,
}

export default ItemCard
