import React from 'react'
import './App.scss'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { GoogleAnalytics, ScrollReset, CookiesNotification } from 'components'
import { Provider as StoreProvider } from 'react-redux'

import RouteWithSubRoutes from 'utils/subRoutes'
import theme from './theme'
import routesApp from './routes'
import store from './store'

function App() {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollReset />
          <GoogleAnalytics />
          <CookiesNotification />
          <Switch>
            {routesApp.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
          </Switch>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  )
}

export default App
