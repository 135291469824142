import { combineReducers } from 'redux'

import session from './session'
import app from './app'
import store from './store'
import cart from './cart'

const appReducer = combineReducers({
  session,
  app,
  store,
  cart,
})

export default appReducer
