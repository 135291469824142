import { IQueryStore } from 'types/store'
import Base from './modules/base'

class StoreService extends Base {
  getStore(query: IQueryStore) {
    const url = `/v2/olo/store/${query.key}/${query.day}/${query.hours}/${query.minutes}`

    return this.get(url)
  }

  getMenus(id: number, type: string | null) {
    const url = `/v2/olo/menu/${id}/${type}`

    return this.post(url)
  }

  getItem(itemId: number, storeId: number, type: string | null) {
    const url = `/v2/olo/menu/store/${storeId}/item/${itemId}/${type}`
    return this.post(url)
  }

  postCart(data: any) {
    const url = '/v2/olo/order/totalprice'
    return this.post(url, data)
  }
}

export default new StoreService()
