import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

/* import useRouter from 'utils/useRouter'
const { NODE_ENV } = process.env
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID */

interface PageProps {
  title: string
  className: string
}

const Page: FC<PageProps> = ({ title, className = '', children }) => {
  /* const router = useRouter()
  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return
    }

    if (window && window?.gtag) {
      window?.gtag('config', GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title,
      })
    }
  }, [title, router]) */

  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

export default Page
