import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCart, getMenus } from 'actions'
import { STORE_CART } from 'constants/localStorage'
import { hasCart } from 'selectors/cart'
import { Fulfillment } from 'types/store'

export default function useLocalStorage(
  fulfillment: Fulfillment | null,
  store: any,
  storeName: string | null
) {
  const dispatch = useDispatch()
  const cartGenerated = useSelector(hasCart)
  const [cartStorage, setCartStorage] = useState<any>(null)

  let cartSaved: any | null = null
  useEffect(() => {
    if (store && fulfillment) {
      dispatch(getMenus(store.id, fulfillment))

      const cartValuesStorage: string | null = window?.localStorage?.getItem(STORE_CART)
      if (cartValuesStorage) {
        cartSaved = JSON.parse(cartValuesStorage)
        setCartStorage(cartSaved)
      }

      if ((cartSaved && cartSaved?.store_id !== store?.id) || !cartSaved) {
        window.localStorage.removeItem(STORE_CART)
        if (storeName) {
          window.localStorage.clear()
          window.location.href = `/${storeName}`
        }
      } else if (!cartGenerated && cartSaved) {
        dispatch(addCart(cartSaved))
      }
    }
  }, [store, fulfillment])

  return {
    cartSaved: cartStorage,
  }
}
