import React, { FC, useState, useEffect } from 'react'
import { TextField, Grid } from '@mui/material'
import { FormStateProps } from 'types/forms'
import LoadingButton from '@mui/lab/LoadingButton'
import Cards from 'react-credit-cards'
import { useDispatch, useSelector } from 'react-redux'
import { saveCheckoutField, updateBody } from 'actions'
import { useAccept, useSimpleFormData, useSnackbar } from 'hooks'
import { isOnlyNumbers } from 'utils/helper'
import { getPreviousCheckOutData } from 'selectors/cart'

import { initialValues, initialTouched } from './inits'
import schema from './schema'
import useStyles from './style'

interface PaymentProps extends FormStateProps {
  onContinue: (n: number) => void
}

const authData = {
  apiLoginID: process.env.REACT_APP_AUTORIZE_LOGIN_ID || '',
  clientKey: process.env.REACT_APP_AUTORIZE_CLIENT_KEY || '',
}
const isProd = process.env.REACT_APP_CUSTOM_NODE_ENV === 'prod'

const Payment: FC<PaymentProps> = ({ formState, handleObjectChange, onContinue }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const prevData = useSelector(getPreviousCheckOutData)
  const { showMessage } = useSnackbar()
  const [focus, setFocus] = useState<string>('')
  const [verifyingPayment, setVerifyingPayment] = useState<boolean>(false)
  const { dispatchData } = useAccept({ environment: isProd ? 'PRODUCTION' : 'SANDBOX', authData })

  let init = {
    ...initialValues,
  }

  if (prevData?.payment) {
    init = {
      ...init,
      ...prevData?.payment,
    }
  }

  const {
    formState: fState,
    handleFieldChange,
    hasError,
    getHelper,
  } = useSimpleFormData(init, initialTouched, {}, schema)

  useEffect(() => {
    if (formState?.values?.orderRequest?.payment_info?.paymentToken) {
      dispatch(updateBody(formState.values))
    }
  }, [formState])

  useEffect(() => {
    return () => {
      // payment: cardNumber, month, year, cardCode
      const { cardNumber, month, year, cardCode } = fState.values
      const payment = { cardNumber, month, year, cardCode }
      dispatch(saveCheckoutField({ payment }))
    }
  }, [fState.values])

  const handleFocus = (e) => {
    setFocus(e.target.name)
  }

  const handleSubmit = async () => {
    setVerifyingPayment(true)
    try {
      const { messages, opaqueData } = await dispatchData({ cardData: fState.values })
      if (messages.resultCode === 'Ok') {
        const { dataValue } = opaqueData
        handleObjectChange('orderRequest.payment_info.paymentToken', dataValue)
        onContinue(4)
      }
    } catch (error) {
      showMessage('Credit Card invalid')
    } finally {
      setVerifyingPayment(false)
    }
  }

  const validNumbers = (event, field) => {
    const { value } = event.target
    if (!isOnlyNumbers(value)) {
      return
    }
    handleFieldChange(event, field, event.target.value)
  }

  return (
    <Grid
      className={classes.container}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Cards
          cvc={fState.values.cardCode}
          expiry={`${fState.values.month}${fState.values.year}`}
          focused={focus === 'cardCode' ? 'cvc' : focus}
          name=" "
          number={fState.values.cardNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Card Number"
          name="cardNumber"
          required
          fullWidth
          onChange={(event) => validNumbers(event, 'cardNumber')}
          onFocus={handleFocus}
          value={fState.values.cardNumber}
          variant="outlined"
          error={hasError('cardNumber')}
          helperText={getHelper('cardNumber')}
          inputProps={{ maxLength: 16 }}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6} className={classes.date}>
        <TextField
          label="Month"
          name="month"
          onChange={(event) => validNumbers(event, 'month')}
          onFocus={handleFocus}
          value={fState.values.month}
          variant="outlined"
          error={hasError('month')}
          helperText={hasError('month') ? getHelper('month') : 'MM'}
          inputProps={{ maxLength: 2 }}
        />
        <TextField
          label="Year"
          name="year"
          onChange={(event) => validNumbers(event, 'year')}
          onFocus={handleFocus}
          value={fState.values.year}
          variant="outlined"
          error={hasError('year')}
          helperText={hasError('year') ? getHelper('year') : 'YY'}
          inputProps={{ maxLength: 2 }}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          label="CVC"
          name="cardCode"
          onChange={(event) => validNumbers(event, 'cardCode')}
          onFocus={handleFocus}
          value={fState.values.cardCode}
          variant="outlined"
          error={hasError('cardCode')}
          helperText={getHelper('cardCode')}
          inputProps={{ maxLength: 4 }}
          className={classes.cvc}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          disabled={!fState.isValid}
          loading={verifyingPayment}
          size="large"
          fullWidth>
          Continue
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default Payment
