import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { formatCurrency, formatPrice } from 'utils/helper'
import { IPriceDetail, ITotalPrice } from 'utils/prices'

import useStyles from './style'

interface FeesProps {
  countItems: number
  taxesList: ITotalPrice
}

const Fees: FC<FeesProps> = ({ countItems, taxesList }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {taxesList.subTotal && (
        <div className={classes.taxes}>
          <Typography variant="h4">Subtotal {countItems} items</Typography>
          <Typography variant="h4">{taxesList.subTotal}</Typography>
        </div>
      )}
      {taxesList.taxes.map((tax) => {
        if (!tax.isValid) {
          return null
        }
        return (
          <div className={classes.taxes} key={`${tax.formattedAmount}${tax.formatted}`}>
            <Typography variant="h5">{tax.formatted}</Typography>
            <Typography variant="h5">{tax.formattedAmount}</Typography>
          </div>
        )
      })}
    </div>
  )
}

export default Fees
