import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#96d25f',
    padding: theme.spacing(1),
    fontWeight: 500,
  },
}))

export default useStyles
