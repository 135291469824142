/* eslint-disable */
import { CART } from 'constants/ActionTypes'

const INITIAL_STATE = {
  cart: {
    itemsOrdered: [],
  },
  cartRemote: null,
  body: null,
  isUpdating: false,
  isOrdering: false,
  checkoutFields: {},
  schedule: {
    day: '',
    hour: '',
    dayEpoch: '',
    hourEpoch: '',
  },
  status: 'idle',
}

const store = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CART.ADD_CART: {
      const { payload } = action
      return {
        ...state,
        cart: payload,
      }
    }
    case CART.ADD_ITEM_ORDERED: {
      const { payload } = action

      return {
        ...state,
        cart: {
          ...state.cart,
          itemsOrdered: [...state.cart.itemsOrdered, payload],
        },
      }
    }
    case CART.UPDATE_CART_REMOTE: {
      const { payload } = action

      return {
        ...state,
        cartRemote: payload,
        status: 'idle',
      }
    }
    case CART.CLEAN_CART: {
      return {
        ...INITIAL_STATE,
      }
    }
    case CART.UPDATE_BODY: {
      const { payload } = action
      return {
        ...state,
        body: payload,
      }
    }
    case CART.UPDATE_QTY_ITEM: {
      const { payload, index } = action
      return {
        ...state,
        cart: {
          ...state.cart,
          itemsOrdered: state.cart.itemsOrdered.map((item: any, i) => {
            if (index === i) {
              return {
                ...item,
                quantity: payload,
              }
            }
            return item
          }),
        },
      }
    }
    case CART.IS_UPDATING: {
      const { payload } = action
      return {
        ...state,
        isUpdating: payload,
      }
    }
    case CART.STATUS: {
      const { payload } = action
      return {
        ...state,
        status: payload,
      }
    }
    case CART.IS_ORDERING: {
      const { payload } = action
      return {
        ...state,
        isOrdering: payload,
      }
    }
    case CART.DELETE_ITEM: {
      const { payload } = action
      const currentItems = state.cart.itemsOrdered
      currentItems.splice(payload, 1)

      return {
        ...state,
        cart: {
          ...state.cart,
          itemsOrdered: currentItems,
        },
      }
    }

    case CART.UPDATE_A_FIELD: {
      const { field, value } = action
      if (field in state.cart) {
        return {
          ...state,
          cart: {
            ...state.cart,
            [field]: value,
          },
        }
      }
      return state
    }
    case CART.ADD_FIELD: {
      const { field, value } = action
      return {
        ...state,
        cart: {
          ...state.cart,
          [field]: value,
        },
      }
    }
    case CART.REMOVE_FIELD: {
      const { field } = action
      const oldCart = { ...state.cart }
      if (field in oldCart) {
        delete oldCart[field]
      }
      return {
        ...state,
        cart: oldCart,
      }
    }
    case CART.CHECKOUT_FIELD: {
      const { payload } = action
      if (payload) {
        return {
          ...state,
          checkoutFields: {
            ...state.checkoutFields,
            ...payload,
          },
        }
      }
      return {
        ...state,
        checkoutFields: {},
      }
    }
    case CART.UPDATE_SCHEDULE_FIELD: {
      const { payload } = action
      const { field, value } = payload
      return {
        ...state,
        schedule: {
          ...state.schedule,
          [field]: value,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default store
