import React, { FC } from 'react'

import useStyle from './styles'

interface IScheduled {
  day: string
  hour: string
}
interface ISchedule {
  scheduled: IScheduled
}

const Schedule: FC<ISchedule> = ({ scheduled }) => {
  const classes = useStyle()

  return (
    <div className={classes.root}>
      Scheduled for {scheduled?.day} {scheduled?.hour !== '' ? ` / ${scheduled?.hour}` : ''}
    </div>
  )
}

export default Schedule
