import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    marginTop: '74px',
  },
  navBar: {
    width: 500,
    flexShrink: 0,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      top: 72,
      right: 0,
      height: 'calc(100vh - 72px)',
      position: 'fixed',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  content: {
    width: 'calc(100vw - 540px)',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  powered: {
    background: '#fff',
    position: 'fixed',
    bottom: 0,
    padding: '10px 20px',
  },
}))

export default useStyles
