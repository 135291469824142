import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: 10,
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    padding: 8,
    paddingLeft: 20,
    fontSize: 33,
    color: 'white',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 35,
      paddingLeft: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      paddingLeft: 6,
    },
  },
}))

export default useStyles
