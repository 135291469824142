import validate from 'validate.js'

validate.validators.yearCard = function (value, options, key, attributes) {
  const y = Number(new Date().getFullYear().toString().slice(2, 4))
  if (value < y) {
    return 'is invalid'
  }
  return null
}

export default validate
