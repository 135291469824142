import React, { useEffect, useState, useMemo, memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'

import { Drawer, Hidden, Divider, Paper, Typography, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSelector, useDispatch } from 'react-redux'
import {
  getBodyPost,
  getCart,
  getRemoteCart,
  selectOrdering,
  selectUpdatingCart,
  getScheduled,
} from 'selectors/cart'

import useRouter from 'utils/useRouter'
import { Fees, Tip, ModalMessage } from 'components'
import { v4 as uuidv4 } from 'uuid'
import {
  cleanAfterOrder,
  cleanCartRemote,
  createOrder,
  deleteItemFromCart,
  postCartRemote,
  updateCartQty,
  updateFieldCart,
  makingOrder,
  saveCheckoutField,
} from 'actions'
import { STORE_CART, STORE_UIID_CART } from 'constants/localStorage'
import { TotalPriceGenerator } from 'utils/prices'
import { ICartRemote } from 'types/cart'
import { formatPriceCurrency } from 'utils/helper'
import { useSnackbar } from 'hooks'
import { selectStore } from 'selectors/store'
import { Close } from '@mui/icons-material'

import { PromoCode, ItemsCart, GrandTotal, Delivery, Schedule } from './components'
import useStyles from './style'

const successMessages = [
  "Order received. Hang tight, we'll text you with the time when it will be ready",
  'Order being prepared. Your food will be ready around ',
  'Scheduled',
  'OK',
  'success change',
  'success',
  'success charge',
]
const errorMessages = ['DELIVERY_RESERVATION_FAIL']
interface ItemSelected {
  name: string
  index: number
}

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, isPayment = false } = props
  const classes = useStyles()
  const router = useRouter()
  const storePath = router.location.pathname.split('/')[1]
  const { showMessage } = useSnackbar()

  const dispatch = useDispatch()
  const cart: ICartRemote = useSelector(getRemoteCart)
  const cartLocal = useSelector(getCart)
  const body = useSelector(getBodyPost)
  const store = useSelector(selectStore)
  const isUpdatingCart = useSelector(selectUpdatingCart)
  const isOrdering = useSelector(selectOrdering)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>(null)
  const [errors, setErrors] = useState<string[] | null>(null)

  const [cartId, _] = useState<string | null>(() => {
    const hasCartId = window.localStorage.getItem(STORE_UIID_CART)
    if (hasCartId) {
      return hasCartId
    }
    const newCartId = uuidv4()
    window.localStorage.setItem(STORE_UIID_CART, newCartId)
    return newCartId
  })
  const existCart = cart && cart?.itemsOrdered.length > 0
  const existWarning = cart?.warning_message !== null
  const isCheckout = router?.location?.pathname.match(/checkout/i)
  const scheduled = useSelector(getScheduled)
  const isScheduled = scheduled?.day !== '' && scheduled?.hour !== ''
  const taxes = useMemo(() => {
    if (cart && isCheckout) {
      return TotalPriceGenerator(
        cart?.price_before_taxes,
        cart?.delivery_fee,
        cart?.taxes_and_fees,
        cart?.sales_tax,
        cart?.service_fee,
        cart?.small_order_fee,
        cart?.additional_charge,
        cart?.app_promo_discount,
        cart?.store_promo_discount,
        cart?.tip,
        cart?.consumer_amount,
        cart?.convenience_fee,
        '',
        ''
      )
    }
  }, [cart])

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose() // eslint-disable-line
    }
  }, [router.location.pathname])

  const makeOrder = async () => {
    if (body) {
      try {
        const response: any = await dispatch(createOrder(body))
        const isValidMakerOrder = successMessages.includes(response?.data?.consumer_status)
        const errorOrder = errorMessages.includes(response?.data?.consumer_status)

        if (isValidMakerOrder) {
          setSuccess(true)
          dispatch(saveCheckoutField(null))
          setErrors(null)
        } else if (errorOrder) {
          dispatch(makingOrder(false))
          setSuccess(false)
          setErrors([
            'Unfortunately delivery is not available right now.',
            'Please try picking up your order',
          ])
        } else {
          dispatch(makingOrder(false))
          setSuccess(false)
          setErrors([
            'We are very sorry but the purchase could not be completed due to a payment issue.',
            'Please try again with another payment method.',
          ])
        }
        if (response?.data?.place_order_message && isValidMakerOrder) {
          setMessage(response?.data?.place_order_message)
        }
        setShowModal(true)
      } catch (error) {
        dispatch(makingOrder(false))
        showMessage('Something is wrong! Please try again.')
      }
    }
  }

  const onHandleQty = (newQty: number, currentQty: number, i: number) => {
    if (newQty !== currentQty) {
      dispatch(updateCartQty(newQty, i))
    }
  }

  const onHandleDeleteItem = (itemSelectedToDelete: ItemSelected) => {
    if (itemSelectedToDelete) {
      if (cart.itemsOrdered.length === 1) {
        dispatch(cleanCartRemote())
      }
      dispatch(deleteItemFromCart(itemSelectedToDelete.index))
    }
  }

  useEffect(() => {
    if (isCheckout && cartLocal?.store_id && !isUpdatingCart) {
      dispatch(postCartRemote(cartLocal))
    }
  }, [cartLocal])

  useEffect(() => {
    if (cart?.itemsOrdered?.length === 0) {
      window.localStorage.removeItem(STORE_CART)
    }
  }, [cart])

  const onAddTip = (t: number | string) => {
    dispatch(updateFieldCart('tip', t))
  }
  /* useEffect(() => {
    window.onbeforeunload = () => {
      return 'Data will be lost if you leave the page, are you sure?'
    }
  }, []) */
  const haveCartAndIsChechout = existCart && isCheckout
  /*

  .then((res) => {
      console.log(res)
    })
    .catch((err) => {
      console.log(err)
    }) */
  const closeModalSuccess = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    if (success) {
      dispatch(cleanAfterOrder())
      dispatch(makingOrder(false))
      window.location.href = `/${storePath}`
    } else {
      setShowModal(false)
    }
  }

  const navbarContent = (
    <div className={classes.content}>
      {existCart ? (
        <div className={classes.profile}>
          <Typography variant="h4" gutterBottom>
            Order Summary
          </Typography>
          <Divider />
        </div>
      ) : (
        <div className={classes.empty}>
          <img className={classes.img} alt="Empty cart" src="/images/cartv2.svg" />
          <Typography variant="h4">Empty cart.</Typography>
          <Typography variant="h5">Add items to your order.</Typography>
        </div>
      )}

      {cart && (
        <ItemsCart
          itemsOrdered={cart.itemsOrdered}
          onHandleQty={onHandleQty}
          onHandleDeleteItem={onHandleDeleteItem}
        />
      )}
      {haveCartAndIsChechout && (
        <>
          {taxes && <Fees countItems={cart?.itemsOrdered.length} taxesList={taxes} />}
          <Tip onUpdate={onAddTip} />
          <Divider />
          {isScheduled && <Schedule scheduled={scheduled} />}
          <PromoCode />
          {taxes && <GrandTotal grandTotal={taxes.grandTotal} />}
          {!isScheduled && <Delivery deliveryDate={cart?.estimated_delivery_date} />}
        </>
      )}
      {existWarning && (
        <div className={classes.warning}>
          <Typography variant="body1" color="primary">
            {cart?.warning_message}
          </Typography>
        </div>
      )}
      {existCart && !isCheckout && (
        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          loading={isUpdatingCart}
          component={RouterLink}
          disabled={existWarning}
          loadingIndicator="Updating..."
          to={`/${storePath}/checkout/${cartId}`}>
          Checkout - {formatPriceCurrency(cart?.price_before_taxes)}
        </LoadingButton>
      )}
      {haveCartAndIsChechout && (
        <LoadingButton
          fullWidth
          loading={isUpdatingCart || isOrdering}
          variant="contained"
          loadingIndicator={isOrdering ? 'Ordering...' : 'Updating...'}
          onClick={makeOrder}
          disabled={!body || existWarning}
          size="large">
          Place Order
        </LoadingButton>
      )}
    </div>
  )

  const container = window !== undefined ? () => window?.document.body : undefined

  if (isPayment) {
    return (
      <div className={className}>
        {navbarContent}
        <ModalMessage
          open={showModal}
          message={message}
          success={success}
          handleClose={closeModalSuccess}
          logo={store?.avatar}
          errors={errors}
        />
      </div>
    )
  }

  return (
    <>
      <Drawer
        anchor="right"
        container={container}
        variant="temporary"
        open={openMobile}
        onClose={onMobileClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {
            lg: 'none',
            xs: 'block',
          },
        }}>
        <div className={clsx(classes.root, className)}>
          <div className={classes.close}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onMobileClose}>
              <Close />
            </IconButton>
          </div>
          {navbarContent}
        </div>
      </Drawer>
      <Paper
        sx={{ display: { xs: 'none', md: 'block' } }}
        className={clsx(classes.root, className)}
        elevation={1}
        square>
        {navbarContent}
      </Paper>
      <ModalMessage
        open={showModal}
        message={message}
        success={success}
        handleClose={closeModalSuccess}
        logo={store?.avatar}
        errors={errors}
      />
    </>
  )
}

export default memo(NavBar)
