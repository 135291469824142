import React, { FC } from 'react'
import { Typography } from '@mui/material'

import useStyles from './style'

interface GTotalProps {
  grandTotal?: string | null
}

const GrandTotal: FC<GTotalProps> = ({ grandTotal }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="h3">Total</Typography>
      <Typography variant="h3">{grandTotal}</Typography>
    </div>
  )
}

export default GrandTotal
