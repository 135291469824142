import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: 20,
  },
  sizeTitle: {
    fontSize: 45,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    marginTop: 10,
  },
  text: {
    paddingRight: 10,
    marginRight: 0,
    fontSize: 14,
    letterSpacing: -0.05,
    lineHeight: '21px',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  icons: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  withDot: {
    '&::before': {
      paddingRight: 5,
      marginTop: 4,
      [theme.breakpoints.up('sm')]: {
        content: '"-"',
      },
    },
  },
}))

export default useStyles
