import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  items: {
    marginBottom: 20,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

export default useStyles
