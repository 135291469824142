import React, { FC } from 'react'
import { Button, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectOrdering } from 'selectors/cart'

import useStyles from './style'

interface HeaderProps {
  step: number
  title: string
  isDone?: boolean | null
  isSelect?: boolean | null
  action?: string | null | undefined
  onHandle?: any | null
}

const HeaderStep: FC<HeaderProps> = ({ step, title, action, isDone, isSelect, onHandle }) => {
  const classes = useStyles()
  const isOrdering = useSelector(selectOrdering)

  const handleClick = () => {
    if (onHandle) {
      onHandle(step)
    }
  }

  return (
    <div className={clsx(classes.container, isSelect && classes.containerSelect)}>
      <div className={classes.info}>
        {isDone ? (
          <CheckCircleIcon className={classes.stepInSelect} color="success" />
        ) : (
          <div
            className={clsx(classes.step, isSelect ? classes.stepOnSelect : classes.stepInSelect)}>
            {step}
          </div>
        )}
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.action}>
        {action && isDone && (
          <Button size="small" aria-label="edit step" disabled={isOrdering} onClick={handleClick}>
            {action}
          </Button>
        )}
      </div>
    </div>
  )
}
HeaderStep.defaultProps = {
  action: null,
  onHandle: null,
  isDone: false,
  isSelect: false,
}

export default HeaderStep
