import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: 30,
    paddingTop: 15,
    flexDirection: 'column',
  },
  forms: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

export default useStyles
