import { useState, useEffect, useRef, useCallback } from 'react'

const StickyHeader = (topLimit = 72, defaultSticky = false) => {
  const [isSticky, setIsSticky] = useState<boolean>(defaultSticky)
  const tableRef = useRef<any>(null)

  const toggleSticky = useCallback(
    ({ top, bottom }) => {
      // if (top <= topLimit && bottom > 2 * 68) {
      if (top <= topLimit) {
        !isSticky && setIsSticky(true) // eslint-disable-line
      } else {
        isSticky && setIsSticky(false) // eslint-disable-line
      }
    },
    [isSticky]
  )

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        toggleSticky(tableRef.current.getBoundingClientRect())
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [toggleSticky])

  return { tableRef, isSticky }
}

export default StickyHeader
