import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {},
  counter: {
    display: 'flex',
    alignItems: 'center',
  },
  qty: {
    width: 60,
  },
}))

export default useStyles
