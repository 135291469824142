import validate from 'utils/validations'

export default {
  cardNumber: {
    presence: true,
    format: {
      pattern: /^(34|37|4|5[1-5]).*$/,
      message: (value, attribute, validatorOptions, attributes, globalOptions) => {
        return validate.format('^%{num} is not a valid credit card number', {
          num: value,
        })
      },
      length: (value, attributes, attributeName, options, constraints) => {
        if (value) {
          // Amex
          if (/^(34|37).*$/.test(value)) return { is: 15 }
          // Visa, Mastercard
          if (/^(4|5[1-5]).*$/.test(value)) return { is: 16 }
        }
        // Unknown card, don't validate length
        return false
      },
    },
  },
  month: {
    presence: true,
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 12,
    },
  },
  year: {
    presence: true,
    yearCard: true,
    numericality: true,
  },
  cardCode: {
    presence: true,
    numericality: true,
    length: {
      minimum: 3,
      maximum: 4,
    },
  },
}
