import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Fulfillment } from 'types/store'
import { selectStore } from 'selectors/store'

interface IUseOrderInfo {
  name: string
  value: string
}

function useOrderInfo(type: Fulfillment | null) {
  const store = useSelector(selectStore)
  const [selectedData, setSelectedData] = useState<IUseOrderInfo[] | null>(null)
  const address = store?.address.address_print
  // const distance = store?.distance ? `${store?.distance} mi` : '-'
  const ready = store?.store_consumer_time ? `${store?.store_consumer_time} min` : '-'
  const data = {
    PICKUP: [
      { name: 'Pick up your orden at', value: address },
      // { name: 'Distance', value: distance },
      { name: 'Ready Time', value: ready },
    ],
    CURBSIDE: [
      { name: 'Pick up your orden at', value: address },
      // { name: 'Distance', value: distance },
      { name: 'Ready Time', value: ready },
    ],
    DINEIN: [
      { name: 'Pick up your orden at', value: address },
      // { name: 'Distance', value: distance },
      { name: 'Ready Time', value: ready },
    ],
    DELIVERY: [
      // { name: 'Pick up your orden at', value: store?.address?.address_print },
      { name: 'Delivery Time', value: store?.delivery_time ? `${store?.delivery_time} min` : '-' },
    ],
  }
  useEffect(() => {
    if (type && data[type]) {
      setSelectedData(data[type])
    }
  }, [type])

  return selectedData
}

export default useOrderInfo
