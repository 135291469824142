import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: 5,
    padding: 10,
    gap: 10,
  },
  info: {
    padding: 3,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 150,
  },
  infoBorder: {
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
}))

export default useStyles
