import { formatCurrency, formatPrice, formatPriceCurrency } from 'utils/helper'

const PERCENT = '15%'

export interface IPriceDetail {
  formatted: string
  formattedAmount: string
  // isInvalid: boolean
  isValid: boolean
  info: string
}
const PriceDetail = (
  title: string,
  amount: number,
  operation: string,
  info: string
): IPriceDetail => {
  const formatted = amount > 0 ? `${title}` : ''
  const formattedAmount = `${operation}${formatCurrency(formatPrice(amount))}`
  const isValid = amount > 0
  return {
    formatted,
    formattedAmount,
    isValid,
    info,
  }
}

const getInfoOfTaxesAndFees = (
  taxes: number | undefined,
  fees: number | undefined,
  subtotal: number,
  smallFee: number | undefined
): string => {
  let info = ''
  if (taxes && taxes > 0) {
    info = `Sales tax: ${formatCurrency(formatPrice(taxes))}`
  }

  if (fees && fees > 0) {
    info = `${info}\nService Fee: ${formatCurrency(
      formatPrice(fees)
    )}\nThis ${PERCENT} service fee helps us operate Lovvett.\n`
  }

  const payMoney = 20 - subtotal
  if (smallFee && smallFee > 0 && payMoney > 0) {
    const sf = formatCurrency(formatPrice(smallFee))
    const pm = formatCurrency(formatPrice(payMoney))
    info = `${info}\nSmall Order Fee: ${sf}\nSave ${sf} when you add ${pm} to your order`
  }
  return info.trim()
}

export interface ITotalPrice {
  subTotal?: string | null
  grandTotal?: string | null
  taxes: IPriceDetail[]
}

export const TotalPriceGenerator = (
  subtotal: number,
  deliveryFee?: number,
  taxesFees?: number,
  taxes?: number,
  fees?: number,
  smallFee?: number,
  additionalCharge?: number,
  appPromoDiscount?: number,
  storePromoDiscount?: number,
  tip?: number,
  total?: number,
  convenienceFee?: number,
  message?: string,
  warningMessage?: string
): ITotalPrice => {
  const taxesList: IPriceDetail[] = [
    PriceDetail(
      'Delivery fee',
      deliveryFee || 0,
      '+',
      'Delivery fee varies from each restaurant based on your location and other factors.'
    ),
    PriceDetail(
      'Taxes & fees',
      taxesFees || 0,
      '+',
      getInfoOfTaxesAndFees(taxes, fees, subtotal, smallFee)
    ),
    PriceDetail('Taxes', taxes || 0, '+', ''),
    PriceDetail('Fees', fees || 0, '+', ''),
    PriceDetail('Service Fee', convenienceFee || 0, '+', ''),
    PriceDetail('Small order fee', smallFee || 0, '+', ''),
    PriceDetail('Additional charge', additionalCharge || 0, '+', ''),
    PriceDetail('Promotions', appPromoDiscount || 0, '-', ''),
    PriceDetail('Promotions', storePromoDiscount || 0, '-', ''),
    PriceDetail('Tip', tip || 0, '+', ''),
  ]

  return {
    taxes: taxesList,
    subTotal: formatPriceCurrency(subtotal),
    grandTotal: formatPriceCurrency(total),
  }
}
