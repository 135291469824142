import React, { useState, useEffect, FC } from 'react'
import {
  Checkbox,
  LinearProgress,
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  ListSubheader,
  FormControlLabel,
  Alert,
  Chip,
} from '@mui/material'
import useStyles from './style'

interface OptionProps {
  id: string
  name: string
  value: number
  suspended: boolean
}

interface ItemProps {
  id: number
  name: string
  option_list: OptionProps[] // eslint-disable-line
  select_free: number
  select_least: number
  select_most: number
}
interface ListProps {
  onSelectItems: (data: any, valid: boolean) => void
  modifiers: ItemProps[]
  setValidator: (s: boolean, va: (v: boolean) => void) => void
}

const validame = (
  mods, // modifiers
  modifiersLocal: ItemProps[] | null | undefined = null, // modifiers selected
  selecteds = false
) => {
  const msgs: string[] = []
  let m: ItemProps | null | undefined
  mods.forEach((it: ItemProps, i) => {
    m = it
    let existLocal = false
    if (selecteds && modifiersLocal) {
      const ml = modifiersLocal.find((mod: ItemProps) => mod.id === it.id) //
      if (ml) {
        m = ml
        existLocal = true
      }
    }
    // msg[i] = ''
    // least > 0: mínimo x cantidad
    // most > 0: máximo x cantidad
    // free > 0: si hay gratis elegir hasta X cantidad gratis
    if (m) {
      const { select_free: selectFree, select_most: selectMost, select_least: selectLeast } = m
      if (selecteds && modifiersLocal && existLocal) {
        const count = m.option_list.length
        if (selectLeast > 0 && count < selectLeast) {
          msgs.push(`You have to choose ${selectLeast} items`)
        } else if (selectMost > 0 && count > selectMost) {
          msgs.push(`You can choose up to ${selectMost} items`)
        } else if (selectFree > 0 && count > selectFree) {
          msgs.push(`You can choose only ${selectFree} items as free`)
        } else {
          msgs.push('')
        }
      } else {
        const empty = ''
        if (selectLeast > 0) {
          msgs.push(`You have to choose ${selectLeast} items`)
        } else if (selectMost > 0) {
          msgs.push(`You can choose up to ${selectMost} items`)
        } else if (selectFree > 0) {
          msgs.push(`You can choose only ${selectFree} items as free`)
        } else {
          msgs.push(empty)
        }
      }
    }
  })

  return msgs
}

const ListChecked: FC<ListProps> = ({ modifiers, onSelectItems, setValidator }) => {
  const classes = useStyles()
  const [messagesValidation, setMessagesValidation] = useState<string[]>([])
  const [showMessage, setShowMessage] = useState<boolean>(false)

  const [itemsChecked, setItemsChecked] = useState<any[]>([])

  const [isValid, setIsValid] = useState<boolean>(() => {
    if (modifiers.length === 0) {
      return true
    }
    return false
  })

  const handleToggle = (value: any, itemSelected: ItemProps) => () => {
    const currentItemIndex = itemsChecked.findIndex((mod: any) => mod.id === itemSelected.id)
    const newItemChecked = [...itemsChecked]
    const { id, name: nameItem } = itemSelected
    const itemSelectedReduced = {
      id,
      name: nameItem,
    }
    const { id: idValue, name, value: valueOption } = value
    const valueReduced = {
      id: idValue,
      name,
      value: valueOption,
    }
    if (currentItemIndex === -1) {
      const newItem = {
        ...itemSelectedReduced,
        option_list: [valueReduced],
      }
      newItemChecked.push(newItem)
    } else {
      const currentIndex = newItemChecked[currentItemIndex]?.option_list.findIndex(
        (i: any) => i.id === valueReduced.id
      )
      const newChecked = [...newItemChecked[currentItemIndex]?.option_list]

      if (currentIndex === -1) {
        newChecked.push(valueReduced)
      } else {
        newChecked.splice(currentIndex, 1)
      }
      newItemChecked[currentItemIndex].option_list = newChecked
    }

    if (newItemChecked[currentItemIndex]?.option_list.length === 0) {
      newItemChecked.splice(currentItemIndex, 1)
    }
    setItemsChecked(newItemChecked)
  }

  const isChecked = (value: any, itemSelected: ItemProps) => {
    const selectedIndex = itemsChecked.findIndex((val: any) => val.id === itemSelected.id)
    if (selectedIndex !== -1) {
      return (
        itemsChecked[selectedIndex].option_list.findIndex((opt: any) => opt.id === value.id) !== -1
      )
    }
    return false
  }

  useEffect(() => {
    setValidator(isValid, setShowMessage)
  }, [])

  useEffect(() => {
    let toVerify: string[]
    if (itemsChecked.length > 0) {
      toVerify = validame(modifiers, itemsChecked, true)
    } else {
      toVerify = validame(modifiers)
    }

    const verify = toVerify.find((tv) => tv !== '') === undefined
    setMessagesValidation(toVerify)
    onSelectItems?.(itemsChecked, verify)
    setIsValid(verify)
  }, [itemsChecked])

  if (!modifiers) {
    return <LinearProgress />
  }

  if (modifiers.length === 0) {
    return null
  }

  return (
    <div className={classes.modifiers}>
      <List>
        {modifiers.map((modifier, kindex) => (
          <li key={`section-${modifier.name}`}>
            <ul>
              <ListSubheader>
                <div className={classes.modifierHeader}>
                  <span>{modifier.name}</span>
                  {modifier.select_least > 0 && (
                    <Chip
                      label="Required"
                      size="small"
                      color={
                        messagesValidation.length > 0 && messagesValidation[kindex] === ''
                          ? 'success'
                          : 'error'
                      }
                    />
                  )}
                </div>
                {showMessage &&
                  messagesValidation.length > 0 &&
                  messagesValidation[kindex] !== '' && (
                    <Alert severity="error">{messagesValidation[kindex]}</Alert>
                  )}
              </ListSubheader>
              {modifier?.option_list?.length > 0 &&
                modifier?.option_list?.map((item) => {
                  const labelId = `checkbox-list-label-${item?.id}`
                  return (
                    <ListItem
                      key={`item-${modifier}-${item?.id}`}
                      secondaryAction={<Typography variant="h5">$ {item.value}</Typography>}
                      disablePadding>
                      <ListItemButton role={undefined} onClick={handleToggle(item, modifier)} dense>
                        <ListItemIcon>
                          <FormControlLabel
                            control={
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={isChecked(item, modifier)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            }
                            label={item.name}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  )
                })}
            </ul>
          </li>
        ))}
      </List>
    </div>
  )
}

export default ListChecked
