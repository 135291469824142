import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import rootReducer from 'reducer'

const middlewares = [thunkMiddleware]
const middlewareEnhancer = composeWithDevTools(compose(applyMiddleware(...middlewares)))
// const store = createStore(rootReducer, /* preloadedState, */ middlewareEnhancer)
const store = createStore(rootReducer, {}, middlewareEnhancer)

export default store
