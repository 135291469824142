import React, { useState, useEffect, FC } from 'react'
import { TextField, IconButton } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import useStyles from './style'

interface CounterProps {
  onUpdateQty?: (data: any) => void
  count?: number
  disabled?: boolean
  disabledPlus?: boolean
  limit: number | null
}

const Counter: FC<CounterProps> = ({
  count = 1,
  onUpdateQty,
  disabled = false,
  disabledPlus = false,
  limit = null,
}) => {
  const classes = useStyles()
  const [qty, setQty] = useState<number>(count)
  const addQty = (isAdd = true) => {
    if (qty === 1 && !isAdd) {
      return
    }
    if (isAdd) {
      setQty(qty + 1)
    } else {
      setQty(qty - 1)
    }
  }

  useEffect(() => {
    onUpdateQty?.(qty)
  }, [qty])

  const onChangeValue = (event) => {
    const v: number = parseInt(event.target.value, 10)
    if (v < 1 || Number.isNaN(v)) {
      setQty(1)
    } else if (limit && v > limit) {
      setQty(limit)
    } else {
      setQty(v)
    }
  }

  return (
    <div className={classes.counter}>
      <IconButton
        size="small"
        aria-label="less"
        disabled={qty <= 1 || disabled}
        onClick={() => addQty(false)}
        color="secondary">
        <IndeterminateCheckBoxIcon />
      </IconButton>
      <TextField
        id="outlined-basic"
        value={qty}
        size="small"
        variant="outlined"
        className={classes.qty}
        inputProps={{ style: { textAlign: 'center' } }}
        onChange={onChangeValue}
        disabled={disabled}
      />
      <IconButton
        size="small"
        aria-label="add"
        disabled={disabled || disabledPlus || qty === limit}
        onClick={() => addQty()}
        color="secondary">
        <AddBoxIcon />
      </IconButton>
    </div>
  )
}

export default Counter
